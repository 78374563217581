// demandes.actions.js
import keys from './demandes.keys';
import axios from "../../custom/axios";
import {Mquery} from "../../functions/MakeQuery";
import {toast} from "react-hot-toast";
import {GetAllStudents} from "../students/student.actions";

export const fetchAllDemands = (query = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: keys.payload,
                value: true,
            });
            const response = await axios.get(
                "/api/v1/demande/getAll",
                Mquery(query)
            );
            dispatch({
                type: keys.set_students,
                value: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: keys.payload,
                value: false,
            });
        }
    };
};




export const DeleteDemandByAdmin = (demand, callback) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: keys.payload,
                value: true,
            });
            const response = await axios.delete(`/api/v1/demande/delete/${demand._id}`);
            console.log(response);
            dispatch({
                type: keys.payload,
                value: false,
            });
            toast.success(`Demand Deleted Successfully`);
            dispatch(fetchAllDemands());
            callback();
        } catch (error) {
            dispatch({
                type: keys.payload,
                value: false,
            });
        }
    };
};


export const AcceptDemandByAdmin = (demand, callback) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: keys.payload,
                value: true,
            });
            const response = await axios.get(`/api/v1/demande/accept/${demand._id}`);
            console.log(response);
            dispatch({
                type: keys.payload,
                value: false,
            });
            toast.success(`Demand Accepted Successfully`);
            dispatch(fetchAllDemands());
            callback();
        } catch (error) {
            dispatch({
                type: keys.payload,
                value: false,
            });
        }
    };
};