import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllDemands } from "../../../redux/demands/demandes.actions";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";
import ShowDemande from "./popups/ShowDemande";
import DelDemande from "./popups/DeleteDemande";
import FilterDemands from "./Filter"
const GestDemandes = () => {
    const dispatch = useDispatch();
    const stDemands = useSelector((state) => state.demandesReducer.demands) || [];
    const dt = useRef(null);


    const [suppDialogue, setSuppDialog] = useState(false);
    const [shoSDialogue, setShoSDialog] = useState(false);
    const [selectedDemand, setSelectedDemand] = useState(null);

    const [page, setPage] = useState({ p: 0, l: 10 });
    const max = 10000;
    const [filter, setFilter] = useState({ firstName: "", lastName: "" });

    useEffect(() => {
        dispatch(fetchAllDemands());
    }, [dispatch]);

    // Open the modal and set the selected demand
    const openShoSDialogue = (row) => {
        setShoSDialog(true); // Open the modal
        setSelectedDemand(row); // Set the selected demand to show in the modal
    };

    const openSuppDemande = (row) =>{
        setSuppDialog(true)
        setSelectedDemand(row); // Set the selected demand to show in the modal


    }

    // Handle closing of modal
    const handleClose = () => {
        setShoSDialog(false);
        setSuppDialog(false)
    };
    const filteredDemands = stDemands.filter((demand) => {
        const firstNameMatch = demand?.User?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase());
        const lastNameMatch = demand?.User?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase());
        const statutMatch = filter.statut ? demand.statut === filter.statut : true;
        const verificationStatusMatch = filter.verificationStatus 
            ? demand.verficationStatus === filter.verificationStatus 
            : true;
    
        return firstNameMatch && lastNameMatch && statutMatch && verificationStatusMatch;
    });

    const ShowStudent = (row) => (
        <div className="flex">
            <AvatarComponent
                src={row?.User?.picture || null}
                circle={true}
                name={row?.User?.email || "N"}
                lastname={row?.User?.lastName || "N"}
            />
            <div className="flex flex-column justify-content-center ml-2">
                <span className="font-semibold">{`${row?.User?.firstName || "N"} ${row?.User?.lastName || "N"}`}</span>
                <span className="text-600">{row?.User?.email || "No Email"}</span>
            </div>
        </div>
    );

    const StatutColumn = (row) => {
        const color = row.statut === "verfie" ? "green" : row.statut === "attente" ? "orange" : "red";
        const text = row.statut || "Pending";
        return <Badge type={color}>{text}</Badge>;
    };

    const TransactionColumn = (row) => row.transaction || "Not Available";
    const UniversiteColumn = (row) => row?.Universite?.nom || "Not Available";
    const DestinationColumn = (row) => (row?.Universite?.pays || "Not Available");
    const FormationColumn = (row) => row?.formation?.nom || "Not Available";

    const IsAccepted = (row) => {
        const color = row.verficationStatus === "Accepted" ? "green" : "red";
        const text = row.verficationStatus === "Accepted" ? "Yes" : "No";
        return <Badge type={color}>{text}</Badge>;
    };

    const actionBodyTemplate = (row) => (
        <div className="actions" style={{ display: "flex", alignItems: "center" }}>
            <Button
                icon="pi pi-eye"
                tooltip="View Details"
                tooltipOptions={{ position: "bottom" }}
                className="p-button-rounded p-button-text p-button-help mr-2"
                onClick={() => openShoSDialogue(row)} // Pass clicked row to the function
            />
            <Button
                icon="pi pi-trash"
                tooltip="Delete Demand"
                tooltipOptions={{ position: "bottom" }}
                className="p-button-rounded p-button-text p-button-danger"
                onClick={() => openSuppDemande(row)} // Pass clicked row to the function

            />
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar className="mb-4" left={() => <h5 className="m-0 mr-2">Manage Demands</h5>} />

                    {shoSDialogue && ( // Only render modal if selectedDemand exists
                        <ShowDemande
                            open={shoSDialogue}
                            handleClose={handleClose}
                            value={selectedDemand} // Pass the selected demand
                            title={`Demand Details`}
                        />
                    )}

                    { suppDialogue && (
                        <DelDemande
                            open={suppDialogue}
                            handleClose={handleClose}
                            value={selectedDemand} // Pass the selected demand
                            title={`Delete the Demand of the Student ${selectedDemand.User?.firstName} ${selectedDemand.User?.lastName}`}
                        />
                    )}

                    <DataTable
                        ref={dt}
                        value={filteredDemands}
                        dataKey="_id"
                        className="datatable-responsive"
                        emptyMessage="No Demand found."
                        header={<FilterDemands init_filter={filter} setFilter={setFilter} />}
                        responsiveLayout="scroll"
                    >
                        <Column header="Student" body={ShowStudent} />
                        <Column field="statut" header="Status" body={StatutColumn} />
                        <Column field="transaction" header="Transaction" body={TransactionColumn} />
                        <Column field="formation" header="Formation" body={FormationColumn} />
                        <Column field="Universite" header="University" body={UniversiteColumn} />
                        <Column field="Destination" header="Destination" body={DestinationColumn} />
                        <Column header="Accepted" body={IsAccepted} />
                        <Column body={actionBodyTemplate} header="Actions" />
                    </DataTable>

                    <Pagination
                        dataLength={stDemands.length}
                        max={max}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default GestDemandes;
