import React, { useState, useEffect } from "react";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

function SupplierFilter({ init_filter, setFilter }) {
    const [localFilter, setLocalFilter] = useState({ ...init_filter });

    useEffect(() => {
        setLocalFilter({ ...init_filter });
    }, [init_filter]);

    const handleFilter = (event) => {
        const { name, value } = event.target;
        setLocalFilter({ ...localFilter, [name]: value });
    };

    const resetFilter = () => {
        const resetValues = { firstName: "", lastName: "", statut: "", verificationStatus: "" };
        setLocalFilter(resetValues);
        setFilter(resetValues); // Reset in parent component
    };

    const applyFilter = () => {
        setFilter({ ...localFilter }); // Apply in parent component
    };

    const statutOptions = [
        { label: "Select Statut", value: "" },
        { label: "Attente", value: "attente" },
        { label: "Verifie", value: "verfie" },
        { label: "Refuse", value: "refuse" }
    ];

    const verificationStatusOptions = [
        { label: "Select Verification Status", value: "" },
        { label: "Accepted", value: "Accepted" },
        { label: "En Attente", value: "en attente" }
    ];

    return (
        <Panel header="Filter Demands" toggleable={true} collapsed={true}>
            <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div className="flex align-items-center flex-wrap">
                    <span className="block m-1 p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="First Name..."
                            type="search"
                            name="firstName"
                            onChange={handleFilter}
                            value={localFilter.firstName || ""}
                        />
                    </span>

                    <span className="block m-1 p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Last Name..."
                            type="search"
                            name="lastName"
                            onChange={handleFilter}
                            value={localFilter.lastName || ""}
                        />
                    </span>

                    <span className="block m-1 p-input-icon-left">
                        <Dropdown
                            name="statut"
                            value={localFilter.statut || ""}
                            options={statutOptions}
                            onChange={handleFilter}
                            placeholder="Statut"
                            className="w-full"
                        />
                    </span>

                    <span className="block m-1 p-input-icon-left">
                        <Dropdown
                            name="verificationStatus"
                            value={localFilter.verificationStatus || ""}
                            options={verificationStatusOptions}
                            onChange={handleFilter}
                            placeholder="Verification Status"
                            className="w-full"
                        />
                    </span>
                </div>

                <div>
                    <Button
                        label="Reset"
                        className="p-button-outlined p-button-warning mr-2"
                        onClick={resetFilter}
                    />
                    <Button
                        label="Search"
                        className="p-button-warning mr-2"
                        onClick={applyFilter}
                    />
                </div>
            </div>
        </Panel>
    );
}

export default SupplierFilter;
