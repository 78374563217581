import keys from "./demandes.keys"
const initialState = {
    demands: [],
    loading: false,
};

const demandesReducer = (state = initialState, action) => {
    switch (action.type) {
        case keys.set_students:
            return { ...state, demands: action.value, loading: false };
        case keys.payload:
            return { ...state, loading: action.value };
        default:
            return state;
    }
};

export default demandesReducer;
