import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import OpenDescription from "./popups/OpenDescription";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Getformations } from "../../../redux/university/university.actions";
import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";
import FilterComp from "./Filter";

const init_filter = {
    nom: "",
};

const initial = {
    nom: "",
    description: "",
    price: "",
    duree: 0,
    avecBac: false,
    universite: {
        nom: "",
        _id: "",
        fullname: "",
        logo: "",
        description: "",
        long_desc: "",
        pays: "",
        address: "",
        tel: "",
        OrganMere: "",
        approved: true,
        cover: ""
    },
};

const Formations = () => {
    let emptyItem = { ...initial };
    const formations = useSelector((state) => state.FormationReducer.formations);
    const dispatch = useDispatch();
    const histo = useHistory();
    
    const [Item, setItem] = useState(emptyItem);
    const [selectedOption, setSelectedOption] = useState("all");

    //-------------------------------- Pagin & Filter --------------------------------------------
    const [page, setPage] = useState({ p: 0, l: 10 });
    const max = 10000;
    const [Filter, setFilter] = useState({ ...init_filter });

    //-------------------------------- Dialog States --------------------------------------------
    const [descDialogue, setdescDialogue] = useState(false);
    const opendescDialogue = (row) => {
        setdescDialogue(true);
        setItem({ ...row });
    };
    const handleClose = () => {
        setdescDialogue(false);
        setItem({});
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
    };

    const dt = useRef(null);

    const filteredFormations = formations.filter((formation) => {
        switch (selectedOption) {
            case "with bac":
                return formation.avecBac;
            case "without bac":
                return !formation.avecBac;
            default:
                return true;
        }
    });

    //-------------------------------- Get Data --------------------------------------------
    const reloadData = () => {
        dispatch(Getformations({ ...Filter, ...page }));
        console.log("--------------------------formationssss", formations);
    };

    useEffect(() => {
        reloadData();
    }, [Filter, page]);

    //------------------------------------------- COLUMNS VALUES ----------------------------------------------

    const ShowMain = (row) => {
        return (
            <div className="flex">
                <div className="flex flex-column justify-content-center ml-2">
                    <span className="font-semibold">{`${row.nom}`}</span>
                </div>
            </div>
        );
    };

    const PriceColumnValue = (row) => `${row?.price}`;
    const PaysColumnValue = (row) => `${row?.Universite.pays}`;
    const DeviseColumnValue = (row) => `${row?.devise}`;
    const uni = (row) => row.Universite.nom ? row.Universite.nom.toUpperCase() : "Not available";

    const AvecBac = (row) => {
        const color = row?.avecBac ? "green" : "red";
        const text = row.avecBac ? "Oui" : "Non";
        return <Badge type={color}>{text}</Badge>;
    };

    const description = (row) => (
        <>
            <h5 className="title_second color_main description_formation text-justify description-limit" style={{ wordWrap: 'break-word' }}>
                {row.description}
            </h5>
            <div className="d-flex align-items-end">
                <p style={{ fontSize: "14px", cursor: "pointer" }} className="color_second" onClick={() => opendescDialogue(row)}>
                    Read more
                </p>
                <button
                    type="button"
                    className="custom-btn position-relative"
                    onClick={() => opendescDialogue(row)}
                    style={{ backgroundColor: 'transparent', border: 'none', color: '#4e86e4' }}
                >
                    <i className="fas fa-book-reader" style={{ color: "#ffb703" }}></i>
                </button>
            </div>
        </>
    );

    //------------------------------------------- RENDER COMPONENT ----------------------------------------------
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toolbar className="mb-4" left={<h5 className="m-0 mr-2">Formations</h5>} right={<Button icon="pi pi-refresh" className="p-button-primary mr-2" onClick={reloadData} />} />
                    
                    <div className="my-2">
                        <Button label="Tous" className={selectedOption === "all" ? "p-button-primary" : "p-button-outlined"} onClick={() => handleSelect("all")} />
                        <Button label="Avec Bac" className={selectedOption === "with bac" ? "p-button-primary" : "p-button-outlined"} onClick={() => handleSelect("with bac")} />
                        <Button label="Sans Bac" className={selectedOption === "without bac" ? "p-button-primary" : "p-button-outlined"} onClick={() => handleSelect("without bac")} />
                    </div>

                    <DataTable
                        ref={dt}
                        dataKey="_id"
                        value={filteredFormations}
                        className="datatable-responsive"
                        emptyMessage="No formation found."
                        header={<FilterComp init_filter={init_filter} setFilter={setFilter} />}
                        responsiveLayout="scroll"
                    >
                        <Column header="Name" body={ShowMain} />
                        <Column field="price" header="Price" body={PriceColumnValue} />
                        <Column field="devise" header="Devise" body={DeviseColumnValue} />
                        <Column field="universite" header="Universite" body={uni} />
                        <Column field="pays" header="Pays" body={PaysColumnValue} />
                        <Column field="avecBac" header="Avec Bac" body={AvecBac} />
                        <Column field="Description" header="Description" body={description} />
                    </DataTable>

                    {Item && descDialogue && (
                        <OpenDescription open={descDialogue} handleClose={handleClose} value={Item} title={`${Item}`} />
                    )}

                    <Pagination max={max} onPageChange={setPage} />
                </div>
            </div>
        </div>
    );
};

export default Formations;
