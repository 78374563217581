const keys = {
  set_student: "set_student",
  payload: "payloade_student",
  set_students: "set_students",
  all: "all_student",
  setStDemands: "set_students_demandes"

};

export default keys;
