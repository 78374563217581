import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetAllStudents } from "../../../redux/students/student.actions";
import { GetDemandsByIdStudent } from "../../../redux/students/student.actions"; // Import your demand fetch action
import { GetOneUniv } from "../../../redux/university/university.actions"; // Import your university fetch action
import Badge from "../../../MyComponents/DataDisplay/Badge";
import Pagination from "../../../MyComponents/Pagination/Pagination";
import BlockStudent from "./popups/BlockStudent";
import DelStudent from "./popups/DelStudent";
import ShowStudent from "./popups/ShowStudent";
import UpdateStudent from "./popups/UpdateStudent";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import FilterComp from "./Filter";
import { init_student } from "../../../redux/students/student.reducer";

const init_filter = {
  pays: "",
  suspended: "",
  verified: "",
};

const GestStudents = () => {
  const students = useSelector((state) => state.StudentReducer.students);
  const stDemands = useSelector((state) => state.StudentReducer.stDemands);
  const dispatch = useDispatch();
  const histo = useHistory();
  const [Item, setItem] = useState({ ...init_student });
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10000;
  const [Filter, setFilter] = useState({ ...init_filter });
  const [blockDialog, setBlockDialog] = useState(false);
  const [suppDialogue, setSuppDialog] = useState(false);
  const [shoSDialogue, setShoSDialog] = useState(false);
  const [updDialogue, setUpdtDialog] = useState(false);
  const dt = useRef(null);
  
  // State to hold universities associated with students' demands
  const [universityData, setUniversityData] = useState({});
  const [demandStatuses, setDemandStatuses] = useState({});



  const reloadData = async () => {
    console.log('Reloading data...');
    await dispatch(GetAllStudents({ ...Filter, ...page }));
    
    // Fetch demands for each student
    const newUniversityData = {};
    const newDemandStatuses ={};
    console.log('Fetching demands for each student...');
    for (const student of students) {
      console.log('Processing student:', student.id);
      const demands = await GetDemandsByIdStudent(student.id)(dispatch)
      // Wait for the action to finish executing and return the value
      console.log('Demands for student:', demands);
      console.log(demands.length);
      if (demands && demands.length) {
        const demandStatuses= demands.map(demande=> demande.statut);
        console.log('delandStatuses=', demandStatuses);
        newDemandStatuses[student.id]=demandStatuses;
        for (const demand of demands) {
          console.log('Demand:', demand);
          console.log('demand.university:', demand.Universite);
          // Fetch the university for each demand
          if (demand.Universite) {
            console.log('Fetching university for demand:', demand.Universite);
            const university = await GetOneUniv(demand.Universite)(dispatch);
            console.log('University data:', university);
            if (university) {
              newUniversityData[student.id] = university.pays; // Store university pays
            }
          }
        }
      }
    }
    console.log('Updated university data:', newUniversityData);
    console.log('Updated demand statuses:', newDemandStatuses);
    setDemandStatuses(newDemandStatuses);
    setUniversityData(newUniversityData); // Update state with university data
    console.log('Data reloaded.');
    console.log(universityData);
  };

  useEffect(() => {
    reloadData();
  }, [Filter, page]);
  useEffect(() => {
    console.log('University data updatedddd:', universityData);
  }, [universityData]);
  const openBlockDialogue = (row) => {
    setBlockDialog(true);
    setItem({ ...row });
  };
  const openSuppDialogue = (row) => {
    setSuppDialog(true);
    setItem({ ...row });
  };
  const openUpdtDialogue = (row) => {
    setUpdtDialog(true);
    setItem({ ...row });
  };
  const openShoSDialogue = (row) => {
    setShoSDialog(true);
    setItem({ ...row });
  };

  const handleClose = () => {
    setItem({ ...init_student });
    setBlockDialog(false);
    setSuppDialog(false);
    setShoSDialog(false);
    setUpdtDialog(false);
  };

  const leftToolbarTemplate = () => (
    <React.Fragment>
      <h5 className="m-0 mr-2">Manage Students</h5>
    </React.Fragment>
  );

  const rightToolbarTemplate = () => (
    <React.Fragment>
      <div className="my-2">
        <Button
          icon="pi pi-refresh"
          className="p-button-primary mr-2"
          onClick={reloadData}
        />
      </div>
    </React.Fragment>
  );

  const ShowMain = (row) => (
    <div className="flex">
      <AvatarComponent
        src={null}
        circle={true}
        name={row?.firstName || "N"}
        lastname={row?.lastName || "N"}
      />
      <div className="flex flex-column justify-content-center ml-2">
        <span className="font-semibold">{`${row?.firstName || "N"} ${row?.lastName || "N"}`}</span>
        <span className="text-600">{row.email}</span>
      </div>
    </div>
  );

  const PhoneColumnValue = (row) => `${row?.tel}`;
  const Pays = (row) => (row?.pays ? row?.pays?.toUpperCase() : "Not Available");
  const IsSuspended = (row) => {
    const color = row?.suspended ? "red" : "green";
    const text = row?.suspended ? "Susp" : "Not Susp";
    return <Badge type={color}>{text}</Badge>;
  };
  const DocumentStatusColumnValue = (row) => {
    const hasAnyDocument = row?.CV || row?.releveDeNote || row?.passeport || row?.motivation;
    const color = hasAnyDocument ? "green" : "red";
    const text = hasAnyDocument ? "Yes" : "No";
    
    return <Badge type={color}>{text}</Badge>;
};


  console.log(students)


  // Update DestinationColumnValue to show university pays
const DestinationColumnValue = (row) => {
  const universityId = row.id;
  const universityDataValues = Object.values(universityData);
  const destinationPays = universityDataValues.find((value) => {
    return universityData[universityId] === value;
  });
  return destinationPays ? destinationPays : "Not Available";
};
 // const UniversityStatusColumnValue = (row) => row?.universityStatus ? row.universityStatus : "Not Available";

 const UniversityStatusColumnValue = (row) => {
  const demandStatusValues = Object.values(demandStatuses);
  const demandStatus = demandStatusValues.find((status) => {
    return demandStatuses[row.id] === status;
  });
  return demandStatus ? demandStatus : "Not Available";
};
  const isVerified = (row) => {
    const color = row?.verified ? "green" : "red";
    const text = row?.verified ? "Verif" : "Not Verif";
    return <Badge type={color}>{text}</Badge>;
  };

  const actionBodyTemplate = (row) => (
    <div className="actions" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
      <Button
        icon="pi pi-ban"
        tooltip="Block Unblock"
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-warning mr-0"
        onClick={() => openBlockDialogue(row)}
      />
      <Button
        icon="pi pi-pencil"
        tooltip="Update Student"
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => openUpdtDialogue(row)}
      />
      <Button
        icon="pi pi-user"
        tooltip="View Student"
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-help mr-0"
        onClick={() => openShoSDialogue(row)}
      />
      <Button
        icon="pi pi-trash"
        tooltip="Delete Student"
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => openSuppDialogue(row)}
      />
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          {Item && blockDialog && (
            <BlockStudent
              open={blockDialog}
              handleClose={handleClose}
              value={Item}
              title={`Block/Unblock Student ${Item.firstName} ${Item.lastName}`}
            />
          )}

          {Item && updDialogue && (
            <UpdateStudent
              open={updDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Updating Student ${Item.firstName} ${Item.lastName}`}
            />
          )}

          {Item && suppDialogue && (
            <DelStudent
              open={suppDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Delete the student ${Item.firstName} ${Item.lastName}`}
            />
          )}

          {Item && shoSDialogue && (
            <ShowStudent
              open={shoSDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Student ${Item.firstName} ${Item.lastName}`}
            />
          )}

<DataTable
        ref={dt}
        value={students}
        dataKey="_id"
        className="datatable-responsive"
        emptyMessage="No Student found."
        header={<FilterComp init_filter={init_filter} setFilter={setFilter} />}
        responsiveLayout="scroll"
      >
        <Column header="Student" body={ShowMain} />
        <Column field="phoneNumber" header="Phone Number" body={PhoneColumnValue} />
        <Column field="pays" header="Country" body={Pays} />
        <Column header="Suspended" body={IsSuspended} />
        <Column header="Verified" body={isVerified} />
        
        {/* New column to show document assignment status */}
        <Column header="Documents" body={DocumentStatusColumnValue} />

        <Column body={actionBodyTemplate} header="Actions" />
      </DataTable>

          <Pagination
            dataLength={students.length}
            max={max}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default GestStudents;
