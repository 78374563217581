import React, {useEffect, useState} from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";


import {
    UserImgComponent,
    DetailBoolItem,
    DetailItem,
} from "../../../../MyComponents/UserDisplay/SupplierCard";

import { init_student } from "../../../../redux/students/student.reducer";
import DelDemande from "./DeleteDemande";
import AcceptDemand from "./AcceptDemand";


function ShowDemande(props) {
    const { open, handleClose, title = "Showing Student", value } = props;


    const {
        User: {
            firstName,
            lastName,
            verified,
            pays,
            email,
            tel,
        },
        verficationStatus,
        statut,
        passeport,
        CV,
        diplome,
        releveDeNote
    } = value || init_student;





    console.log(value)


    const downloadFile = (fileUrl) => {
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileUrl.split('/').pop(); // Set the filename
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // Clean up
            })
            .catch(error => console.error('Error downloading file:', error));
    };

    //-------------------------------- Dialog States --------------------------------------------
    const [suppDialogue, setSuppDialog] = useState(false);
    const [acceptDialogue, setAcceptDialog] = useState(false);
    const openSuppDialogue = () => {
        setSuppDialog(true);
    };
    const openAcceptDialogue = () => {
        setAcceptDialog(true);
    };
    const handleClose2 = () => {
        setAcceptDialog(false);
        setSuppDialog(false);
        handleClose();
    };
    //-------------------------------- Dialog States -------------------------------

    return (
        <Dialog
            visible={open}
            style={{ width: "400px" }}
            header={title}
            modal
            className="p-fluid"
            onHide={handleClose}
        >
            {suppDialogue && (
                <DelDemande
                    open={suppDialogue}
                    handleClose={handleClose2}
                    value={value}
                    title={`Delete the Demand of the Student ${firstName} ${lastName}`}
                    callBack={handleClose}
                />
            )}

            {acceptDialogue && (
                <AcceptDemand
                    open={acceptDialogue}
                    handleClose={handleClose2}
                    value={value}
                    title={`Accept Demande`}
                />
            )}

            <UserImgComponent
                firstName={firstName || "N"}
                lastName={lastName || "N"}
                avatar={null}
            />
            <h4 className=" text-800  text-center mt-0 ">
                {firstName && lastName ? `${firstName} ${lastName}` : "Not Available"}
            </h4>
             {/*<SupplierProdMonthNumbers color={color} nbProds={253} nbMonths={5} /> */}
            <h4 className=" text-800   mt-6 mb-0 ">Details</h4>
            <Divider className="mt-1 mb-1" />

            <DetailItem label="First Name : " value={firstName || "Not Available"} />
            <DetailItem label="Last Name : " value={lastName || "Not Available"} />
            <DetailItem label="Phone Number : " value={tel || "Not Available"} />
            <DetailItem label="Email : " value={email || "Not Available"} />
            <DetailItem label="Country : " value={pays || "Not Available"} />

            <DetailBoolItem
                label="Status : "
                value={statut}
                TrueTxt="Not Suspended"
                FalseTxt="Suspended"
            />

            <DetailBoolItem
                label="Verified : "
                value={verified}
                TrueTxt="Verified"
                FalseTxt="Not Verified"
            />

            <h4 className=" text-800   mt-4 mb-0 ">Student Files</h4>
            <Divider className="mt-1 mb-1" />

            <div className="grid">
                <div className="col-12">
                    <p className="m-0"><strong>CV FILE</strong></p>
                    <a href={CV} className="text-red-500">{CV}</a>
                </div>
                <div className="col-12">
                    <p className="m-0"><strong>DIPLOME</strong></p>
                    <a href={diplome} className="text-red-500">{diplome}</a>                </div>
                <div className="col-12">
                    <p className="m-0"><strong>PASSEPORT</strong></p>
                    <a  href={passeport} className="text-red-500">{passeport}</a></div>
                <div className="col-12">
                    <p className="m-0"><strong>REL-NOTE</strong></p>
                    <a href={releveDeNote} className="text-red-500">{releveDeNote}</a>
                </div>
            </div>

            <h4 className=" text-800   mt-4 mb-0 ">Actions For Demandes</h4>
            <Divider className="mt-1 mb-1" />

            <div className=" flex align-items-center justify-content-evenly mt-4 ">
                { verficationStatus !== "Accepted" ?(
                    <Button
                        label="Accept"
                        className="p-button-warning mx-2"
                        onClick={openAcceptDialogue}
                    />
                ): ""}
                <Button // Delete Account
                    label="Delete"
                    className="p-button-danger mx-2"
                    onClick={openSuppDialogue}
                />
            </div>
        </Dialog>
    );
}

export default ShowDemande;
